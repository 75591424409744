<template>
  <div class="dashboard container">

    <!-- ------------------------------- Modal: Dashboard Columns ------------------------------------------ -->

	<CModal alignment="center" title="Dashboard Columns" color="info" :show.sync="modalDbCol" size="lg">	
		<div slot="header">
			<h5 v-if="existingDbCol">Dashboard {{dbNbr}} - Existing Column</h5>
			<h5 v-else>Dashboard {{dbNbr}} - New Column</h5>
		</div>	  

		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('addUpdateDbCol')">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="modalDbCol=false">Close</CButton>
			<p class="text-danger" align="left">{{alertDbCol}}</p>
			<div>
				<label for="loadingMsgDbCol" v-if="loadingDbCol">{{loadingMsgDbCol}}</label>
				<scale-loader :loading="loadingDbCol"></scale-loader>
			</div>
		</div>	

		<form>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Field Name</label>
				<div v-if="existingDbCol">
					<label class="col-sm-3 col-form-label font-weight-bold text-left">{{dbCol.field}}</label>
				</div>
				<div v-else>
					<input type="text" class="col-sm-20 form-control" v-model="dbCol.field">
				</div>
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Column Title</label>
				<input type="text" class="col-sm-3 form-control" v-model="dbCol.value">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Order</label>
				<input type="number" class="col-sm-3 form-control" v-model="dbCol.order">

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Color</label>
				<input type="text" class="col-sm-3 form-control" v-model="dbCol.color">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Align Text</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="dbCol.alignText">
						<option value="left">Left</option>
						<option value="right">Right</option>
						<option value="center">Center</option>
					</select>
				</div>

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Bold Text</label>
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="dbCol.boldText">
						<option value="yes">Yes</option>
						<option value="no">No</option>
					</select>
				</div>
			</div>
		</form>

	</CModal>

    <!-- ------------------------------- Modal: Dashboard Rows ------------------------------------------ -->

	<CModal alignment="center" title="Dashboard Rows" color="info" :show.sync="modalDbRow" size="lg">	
		<div slot="header">
			<h5 v-if="existingDbRow">Dashboard {{dbNbr}} - Existing Row</h5>
			<h5 v-else>Dashboard {{dbNbr}} - New Row</h5>
		</div>	  

		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('addUpdateDbRow')">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="modalDbRow=false">Close</CButton>
			<p class="text-danger" align="left">{{alertDbRow}}</p>
			<div>
				<label for="loadingMsgDbRow" v-if="loadingDbRow">{{loadingMsgDbRow}}</label>
				<scale-loader :loading="loadingDbRow"></scale-loader>
			</div>
		</div>	

		<form>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Name</label>
				<div v-if="existingDbRow">
					<label class="col-sm-3 col-form-label font-weight-bold text-left">{{dbRow.name}}</label>
				</div>
				<div v-else>
					<input type="text" class="col-sm-20 form-control" v-model="dbRow.name">
				</div>
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Order</label>
				<input type="number" class="col-sm-3 form-control" v-model="dbRow.order">

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Color Stripe</label>
				<!-- <input type="text" class="col-sm-3 form-control" v-model="dbRow.color"> -->
				<div class="col-sm-3 text-left">
					<select class="form-control" v-model="dbRow.color">
						<option value="1">Yes</option>
						<option value="0">No</option>
					</select>
				</div>
			</div>

		</form>

	</CModal>

    <!-- ------------------------------- Modal: Dashboard Cells ------------------------------------------ -->

	<CModal alignment="center" title="Dashboard Cells" color="info" :show.sync="modalDbCell" size="lg">	
		<div slot="header">
			<h5 v-if="existingDbCell">Dashboard {{dbNbr}} - Existing Cell</h5>
			<h5 v-else>Dashboard {{dbNbr}} - New Cell</h5>
		</div>	  

		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('addUpdateDbCell')">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="modalDbCell=false">Close</CButton>
			<p class="text-danger" align="left">{{alertDbCell}}</p>
			<div>
				<label for="loadingMsgDbCell" v-if="loadingDbRow">{{loadingMsgDbCell}}</label>
				<scale-loader :loading="loadingDbCell"></scale-loader>
			</div>
		</div>	

		<form>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Column</label>
				<label class="col-sm-3 col-form-label font-weight-bold text-lefft">{{dbCell.field}}</label>

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Row</label>
				<label class="col-sm-3 col-form-label font-weight-bold text-left">{{dbCell.row}}</label>
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Location</label>
				<div class="col-sm-25 text-left">
					<select class="form-control" v-model="dbCell.location">
						<option v-for="orgLoc in loc_orgList" :value="orgLoc">
							{{ orgLoc }}
						</option>
					</select>
				</div>
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Item1</label>
				<div class="col-sm-25 text-left">
					<select class="form-control" v-model="dbCell.item1">
						<option v-for="orgItem1 in item1s_forCurrentLoc" :value="orgItem1">
							{{ orgItem1 }}
						</option>
					</select>
				</div>

				<label class="col-sm-2 col-form-label font-weight-bold text-right">Item2</label>
				<div class="col-sm-25 text-left">
					<select class="form-control" v-model="dbCell.item2">
						<option v-for="orgItem2 in item2s_forCurrentItem1" :value="orgItem2">
							{{ orgItem2 }}
						</option>
					</select>
				</div>

			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Value</label>
				<input type="text" class="col-sm-3 form-control" v-model="dbCell.value">
			</div>

		</form>

	</CModal>

    <!-- ---------------------------------------  Main  -------------------------------------------------- -->

	<p class="font-weight-bold" align="center">Dashboard - {{ dbNbr }} Configuration</p>

	<div>
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>
	<p class="text-danger" align="left">{{alert}}</p>

	<div align="center">
		<input type="radio" class="mr-1" id="col" value="dbCols" v-model="currentScreen" />
		<label for="col" class="mr-4 font-weight-bold">Columns</label>

		<input type="radio" class="mr-1" id="row" value="dbRows" v-model="currentScreen" />
		<label for="row" class="mr-4 font-weight-bold">Rows</label>

		<input type="radio" class="mr-1" id="cell" value="dbCells" v-model="currentScreen" />
		<label for="cell" class="font-weight-bold">Cells</label>
	</div>
	<br>

   	<!-- ---------------------------------------  Dashboard Columns Maint -------------------------------------------------- -->
	<div v-if="currentScreen == 'dbCols'">
		<!-- <p class="font-weight-bold" align="center">Columns</p> -->
		<vue-good-table ref="dbColsTable" 
								:columns="colsDbCols" 
								:rows="rowsDbCols" 
								@on-row-click="rowChangedDbCol" 
								:selectOptions="{
										enabled: true,
										selectOnCheckboxOnly: false, 
								}">
				<div slot="table-actions">
					<CButton color="dark" class="mr-4" v-on:click.prevent="openNewDbCol()">New</CButton>
				</div>
				<div slot="selected-row-actions">
					<CButton color="dark" v-on:click.prevent="openExistingDbCol()">Details</CButton>
					<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="serverCall('deleteDbCol')">Delete</CButton>
				</div>
		</vue-good-table>
	</div>

   	<!-- ---------------------------------------  Dashboard Rows Maint -------------------------------------------------- -->
	<div v-else-if="currentScreen == 'dbRows'">
		<vue-good-table ref="dbRowsTable" 
								:columns="colsDbRows" 
								:rows="rowsDbRows" 
								@on-row-click="rowChangedDbRow" 
								:selectOptions="{
										enabled: true,
										selectOnCheckboxOnly: false, 
								}">
				<div slot="table-actions">
					<CButton color="dark" class="mr-4" v-on:click.prevent="openNewDbRow()">New</CButton>
				</div>
				<div slot="selected-row-actions">
					<CButton color="dark" v-on:click.prevent="openExistingDbRow()">Details</CButton>
					<CButton color="dark" class="ml-2" v-on:click.prevent="serverCall('deleteDbRow')">Delete</CButton>
				</div>
		</vue-good-table>
	</div>

   	<!-- ---------------------------------------  Dashboard Cells Maint -------------------------------------------------- -->
	<div v-else>
		<vue-good-table ref="dbCellsTable" 
								:columns="colsDbCells" 
								:rows="rowsDbCells" 
								@on-row-click="rowChangedDbCell" 
								:selectOptions="{
										enabled: true,
										selectOnCheckboxOnly: false, 
								}">
				<div slot="table-actions">
					<CButton color="dark" class="mr-4" v-on:click.prevent="serverCall('refreshDbCells')">Refresh All Cells</CButton>
				</div>
				<div slot="selected-row-actions">
					<CButton color="dark" v-on:click.prevent="openExistingDbCell()">Details</CButton>
					<CButton color="dark" class="ml-2" v-on:click.prevent="serverCall('deleteDbCell')">Delete</CButton>
				</div>
		</vue-good-table>
	</div>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker'
import ScaleLoader from "@/views/utils/ScaleLoader"
import { firebaseAuth } from '../../firebaseConfig.js';
import Helper from "../utils/Helper.vue"

export default {
	name: 'location',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {
		return {
			orgId: null,

			alert: '',
            loading: false,
            loadingMsg: null,

			dbNbr: 1,

			currentScreen: 'dbCols',  // dbCols, dbRows, dbCells

			// ************* dbColumns ************
			// field, value, order, text-align, text-bold, color
			
			alertDbCol: '',
            loadingDbCol: false,
            loadingMsgDbCol: null,

			modalDbCol: false,
			dbCol: {},
			curRowDBcol: null,
			existingDbCol: false,

			rowsDbCols: [],
			colsDbCols: [
					{
							label: 'Field',
							field: 'field',
					},
					{
							label: 'Column Title',
							field: 'value',
					},
					{
							label: 'Order',
							field: 'order',
							type: 'number',
					},
					{
							label: 'Align Text',
							field: 'alignText',
					},
					{
							label: 'Bold Text',
							field: 'boldText',
					},
					{
							label: 'Color',
							field: 'color',
					},
			],

			// ************* dbRows ************
			// name, order, color
			
			alertDbRow: '',
            loadingDbRow: false,
            loadingMsgDbRow: null,

			modalDbRow: false,
			dbRow: {},
			curRowDBRow: null,
			existingDbRow: false,

			rowsDbRows: [],
			colsDbRows: [
					{
							label: 'Name',
							field: 'name',
					},
					{
							label: 'Order',
							field: 'order',
							type: 'number',
					},
					{
							label: 'Color',
							field: 'color',
					},
			],

			// ************* dbCells ************
			// row, field, location, item1, item2, value
			
			alertDbCell: '',
            loadingDbCell: false,
            loadingMsgDbCell: null,

			modalDbCell: false,
			dbCell: {},
			curRowDBCell: null,
			existingDbCell: false,

			rowsDbCells: [],
			colsDbCells: [
					{
							label: 'Row Name',
							field: 'row',
							filterOptions: {enabled: true,},
					},
					{
							label: 'Column Name',
							field: 'field',
							filterOptions: {enabled: true,},
					},
					{
							label: 'Location',
							field: 'location',
							filterOptions: {enabled: true,},
					},
					{
							label: 'Item1',
							field: 'item1',
							filterOptions: {enabled: true,},
					},
					{
							label: 'Item2',
							field: 'item2',
							filterOptions: {enabled: true,},
					},
					{
							label: 'Value',
							field: 'value',
							filterOptions: {enabled: true,},
					},
			],

			loc_orgList: [],
			loc_item1_orgList: [],
			item1_orgList: [],
			item1_item2_orgList: [],

			item1s_forCurrentLoc: [],
			item2s_forCurrentItem1: [],

			// *************************

            pagination_options: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [10, 20, 30, 40],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
			},
		}
	},

	computed: {
	},

	watch: {
		// whenever 'dbCell.location' changes, this function will run
		'dbCell.location'(newLoc, oldLoc) {
			// Filter 'loc_item1_orgList' to 'item1s_forCurrentLoc' based on location
			for (const record of this.loc_item1_orgList){
				if (record['location'] == newLoc){
					this.item1s_forCurrentLoc = record['item1s']
				}
			}
		},

		// whenever 'dbCell.item1' changes, this function will run
		'dbCell.item1'(newItem1, oldItem1) {
			// Filter 'item1_item2_orgList' to 'item2s_forCurrentLoc' based on item1
			for (const record of this.item1_item2_orgList){
				if (record['item1'] == newItem1){
					this.item2s_forCurrentItem1 = record['item2s']
				}
			}
		},

	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		// todate = current date
		this.todate = new Date()
		this.dbNbr = this.$route.params['dbNbr']
		this.serverCall('getDashboardConfigData')
	},


	methods: {

        serverCall(name) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getDashboardConfigData":
								self.getDashboardConfigData(idToken)
								break;

							// ****** Dashboard Columns

							case "addUpdateDbCol":
								self.dbColAlert = ""

								if (self.dbCol.field.trim() == "") {
									self.dbColAlert= 'Column Field not specified.'
									return
								}
								if (self.dbCol.order < 0) {
									self.dbColAlert= 'Column order not specified.'
									return
								}

								var rowsTemp = self.rowsDbCols.slice()
								// Prepare new dbCol list
								if (self.existingDbCol){
									rowsTemp[self.curRowDbCol.originalIndex] = self.dbCol
								}
								else{
									rowsTemp.push(self.dbCol)
								}

								// Check if any field is duplicate
								var dbColFields = []
								for (const dbCol of rowsTemp){
									if (dbColFields.includes(dbCol['field'])){
										self.alertDbCol= 'Duplicate field values not allowed'
										return
									}
									else {
										dbColFields.push(dbCol['field'])
									}
								}

								// Update rowsDBcols
								self.rowsDbCols = rowsTemp

								if (self.existingDbCol)
									self.addUpdateDbCol('Update', self.dbCol, idToken)
								else
									self.addUpdateDbCol('Add', self.dbCol, idToken)

								break

							case "deleteDbCol":
								// Check if dashboard column is selected
								if (self.curRowDbCol==null){
									self.alert = "Dashboard Column not selected."
									return
								}

								this.$confirm(self.dbCol.field + " - will be deleted. Are you sure?").then(() => {
									var dbColIndex = self.curRowDbCol['originalIndex']
									self.rowsDbCols.splice(dbColIndex, 1)
									self.addUpdateDbCol('Delete', self.dbCol, idToken)
								})
								break;

							// ****** Dashboard Rows
							
							case "addUpdateDbRow":
								self.dbRowAlert = ""

								if (self.dbRow.name.trim() == "") {
									self.dbRowAlert= 'Row Name not specified.'
									return
								}
								if (self.dbRow.order < 0) {
									self.dbRowAlert= 'Row order not specified.'
									return
								}

								var rowsTemp = self.rowsDbRows.slice()
								// Prepare new dbRow list
								if (self.existingDbRow){
									rowsTemp[self.curRowDbRow.originalIndex] = self.dbRow
								}
								else{
									rowsTemp.push(self.dbRow)
								}

								// Check if any row name is duplicate
								var dbRowNames = []
								for (const dbRow of rowsTemp){
									if (dbRowNames.includes(dbRow['name'])){
										self.alertDbRow= 'Duplicate names for rows not allowed'
										return
									}
									else {
										dbRowNames.push(dbRow['name'])
									}
								}

								// Update rowsDBRows
								self.rowsDbRows = rowsTemp

								if (self.existingDbRow)
									self.addUpdateDbRow('Update', self.dbRow, idToken)
								else
									self.addUpdateDbRow('Add', self.dbRow, idToken)
								break

							case "deleteDbRow":
								// Check if dashboard row is selected
								if (self.curRowDbRow==null){
									self.alert = "Dashboard Row not selected."
									return
								}

								this.$confirm(self.dbRow.name + " - will be deleted. Are you sure?").then(() => {
									var dbRowIndex = self.curRowDbRow['originalIndex']
									self.rowsDbRows.splice(dbRowIndex, 1)
									self.addUpdateDbRow('Delete', self.dbRow, idToken)
								})
								break;

							case "addUpdateDbCell":
								// All 3 fields - location, item1, item2 should be present or not present
								if (self.dbCell['location']!='' || self.dbCell['item1']!='' || self.dbCell['item2']!=''){
									if (self.dbCell['location']=='' || self.dbCell['item1']=='' || self.dbCell['item2']==''){
										self.alertDbCell = "Location, Item1, Item2: All 3 fields should be either specified or unspecified."
										return
									}	
								}
								self.rowsDbCells[self.curRowDbCell.originalIndex] = self.dbCell
								self.addUpdateDbCell('Update', self.dbCell, idToken)
								break;

							case "deleteDbCell":
								// Check if dashboard cell is selected
								if (self.curRowDbCell==null){
									self.alert = "Dashboard Cell not selected."
									return
								}
								this.$confirm("Row " + self.dbCell.row + " - will be deleted. Are you sure?").then(() => {
									var dbCellIndex = self.curRowDbCell.originalIndex
									self.rowsDbCells.splice(dbCellIndex, 1)
									self.addUpdateDbCell('Delete', self.dbCell, idToken)
								})
								break;

							case "refreshDbCells":
								self.refreshDbCells(idToken)
								break

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},

		// ************* Dashboard ************

        getDashboardConfigData(authToken) {
            var self = this
			self.alert = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Dashboard Configuration..."

            var formData = new FormData()
			formData.append('dbNbr', this.dbNbr)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetDashboardConfigData/',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data',
					},
					data: formData,
				})
				.then(function (response) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					if (response.data.err != ''){
						self.alert = response.data.err
					}
					else {
						self.rowsDbCols = response.data.dbCols
						self.rowsDbRows = response.data.dbRows
						self.rowsDbCells = response.data.dbCells
                        self.loc_orgList = response.data.loc_orgList, 
                        self.loc_item1_orgList = response.data.loc_item1_orgList,
                        self.item1_orgList = response.data.item1_orgList,
                        self.item1_item2_orgList = response.data.item1_item2_orgList
					}
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.alert = String(error)
				})

		},

		// Save dashboard column changes
        addUpdateDbCol(opType, dbColLocal, authToken) {
            var self = this
            this.dbColAlert = ""

			// Set loading message
			self.loadingDbCol = true
			self.loadingMsgDbCol = "Adding/Updating Dashboard Column..."

			var formData = new FormData()
			formData.append('dbNbr', this.dbNbr)
			formData.append('opType', opType)
			formData.append('dbCol', JSON.stringify(dbColLocal))

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/AddUpdateDbCol/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(function (response) {
					if (response.data.err != '') {
						self.alertDbCol = response.data.err
					}
					else {
						self.rowsDbCells = response.data.dbCells
						self.modalDbCol = false
					}
					// Reset loading message
					self.loadingDbCol = false
					self.loadingMsgDbCol = null

				})
				.catch(function (error) {
					// Reset loading message
					self.loadingDbCol = false
					self.loadingMsgDbCol = null
					self.alertDbCol = String(error)
				})

		},

		// Save dashboard row changes
        addUpdateDbRow(opType, dbRowLocal, authToken) {
            var self = this
            this.dbRowAlert = ""

			// Set loading message
			self.loadingDbRow = true
			self.loadingMsgDbRow = "Adding/Updating Dashboard Row..."

			var formData = new FormData()
			formData.append('dbNbr', this.dbNbr)
			formData.append('opType', opType)
			formData.append('dbRow', JSON.stringify(dbRowLocal))

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/AddUpdateDbRow/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(function (response) {
					if (response.data.err != '') {
						self.alertDbRow = response.data.err
					}
					else {
						self.rowsDbCells = response.data.dbCells
						self.modalDbRow = false
					}
					// Reset loading message
					self.loadingDbRow = false
					self.loadingMsgDbRow = null

				})
				.catch(function (error) {
					// Reset loading message
					self.loadingDbRow = false
					self.loadingMsgDbRow = null
					self.alertDbRow = String(error)
				})

		},

		// Save dashboard cell changes
        addUpdateDbCell(opType, dbCellLocal, authToken) {
            var self = this
            this.alertDbCell = ""

			// Set loading message
			self.loadingDbCell = true
			self.loadingMsgDbCell = "Updating Dashboard Cell..."

			var formData = new FormData()
			formData.append('dbNbr', this.dbNbr)
			formData.append('opType', opType)
			formData.append('dbCell', JSON.stringify(dbCellLocal))

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/AddUpdateDbCell/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(function (response) {
					if (response.data.err != '') {
						self.alertDbCell = response.data.err
					}
					else {
						self.modalDbCell = false
					}
					// Reset loading message
					self.loadingDbCell = false
					self.loadingMsgDbCell = null

				})
				.catch(function (error) {
					// Reset loading message
					self.loadingDbCell = false
					self.loadingMsgDbCell = null
					self.alertDbCell = String(error)
				})

		},


		// Refresh dashboard cell values
        refreshDbCells(authToken) {
            var self = this
            this.alert = ""

			// Set loading message
			self.loading = true
			self.loadingMsg = "Refreshing Dashboard Cell Values..."

			var formData = new FormData()
			formData.append('dbNbr', this.dbNbr)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/RefreshDashboard/',
					data: formData,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(function (response) {
					if (response.data.err != '') {
						self.alert = response.data.err
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null

				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					self.alert = String(error)
				})

		},

		// ******************** Dashboard Columns ***************

		rowChangedDbCol(params){	
			// Current selected Column
			if (this.curRowDbCol != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowDbCol.originalIndex!=params.row.originalIndex)
					this.curRowDbCol.vgtSelected = false
			}
			// New selected row
			this.curRowDbCol = params.row
			this.dbCol= this.curRowDbCol
		},

		// Open details for existing dashboard column
		openExistingDbCol(){
			this.alertDbCol = null

            // Check if dashboard column is selected
			if (this.curRowDbCol==null){
				this.alert = "Column not selected."
				return
			}
			this.dbCol= this.curRowDbCol
			this.existingDbCol = true
			this.modalDbCol = true
		},

		// Open details for new Column
		openNewDbCol(){
			this.alertDbCol = null

			// Initialize column object
			this.dbCol = {
				field: '',
				value: '',
				order: 0,
				alignText: 'right',
				boldText: 'yes',
				color: '',
				vgtSelected: false
			}

			this.existingDbCol = false
			this.modalDbCol = true
		},

		// ******************** Dashboard Rows ***************

		rowChangedDbRow(params){	
			// Current selected Row
			if (this.curRowDbRow != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowDbRow.originalIndex!=params.row.originalIndex)
					this.curRowDbRow.vgtSelected = false
			}
			// New selected row
			this.curRowDbRow = params.row
			this.dbRow = this.curRowDbRow
		},

		// Open details for existing dashboard Row
		openExistingDbRow(){
			this.alertDbRow = null

            // Check if dashboard row is selected
			if (this.curRowDbRow==null){
				this.alert = "Dashboard Row not selected."
				return
			}
			this.dbRow= this.curRowDbRow
			this.existingDbRow = true
			this.modalDbRow = true
		},

		// Open details for new Dashboard Row
		openNewDbRow(){
			this.alertDbRow = null

			// Initialize row object
			this.dbRow = {
				name: '',
				order: 0,
				color: '0',
				vgtSelected: false
			}

			this.existingDbRow = false
			this.modalDbRow = true
		},

		// ******************** Dashboard Cells ***************

		rowChangedDbCell(params){	
			// Current selected cell
			if (this.curRowDbCell != null){
				// If new cell is not same as previous cell, uncheck previous cell
				if (this.curRowDbCell.originalIndex!=params.row.originalIndex)
					this.curRowDbCell.vgtSelected = false
			}
			// New selected cell
			this.curRowDbCell = params.row
			this.dbCell = this.curRowDbCell
		},

		// Open details for existing dashboard Cell
		openExistingDbCell(){
			this.alertDbCell= null

            // Check if dashboard cell is selected
			if (this.curRowDbCell==null){
				this.alert = "Dashboard Cell not selected."
				return
			}
			this.dbCell= this.curRowDbCell
			this.existingDbCell = true
			this.modalDbCell = true
		},

	},

}
</script>

<style>

</style>

